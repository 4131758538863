import React, { ChangeEvent } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import content from '../content'
import { useOrderFilterInfo } from '../../custom-hooks/useFetchOrderFiltersQuery'
import { buyerListRiskDecisions } from '../../types/buyerRiskDecisions'

const orderFiltersContent = content.pages.orderList.filters

interface OrderFiltersProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  status: string[]
  paymentMethod: string[]
  dateFrom: Dayjs | null
  dateTo: Dayjs | null
  setStatus: (value: string[]) => void
  setPaymentMethod: (value: string[]) => void
  setDateFrom: (value: Dayjs | null) => void
  setDateTo: (value: Dayjs | null) => void
  hideSubmittedDateFilters?: boolean
  customerRiskStatus?: string[]
  setCustomerRiskStatus: (value: string[]) => void
  moreFiltersEnabled?: boolean
}

const OrderListFilters = ({
  searchTerm,
  setSearchTerm,
  status,
  setStatus,
  paymentMethod,
  setPaymentMethod,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  hideSubmittedDateFilters = false,
  customerRiskStatus = [],
  setCustomerRiskStatus,
  moreFiltersEnabled = false
}: OrderFiltersProps) => {
  const { data, isLoading } = useOrderFilterInfo()

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value }
    } = event
    setStatus(typeof value === 'string' ? value.split(',') : value)
  }

  const handlePaymentChange = (event: SelectChangeEvent<typeof paymentMethod>) => {
    const {
      target: { value }
    } = event
    setPaymentMethod(typeof value === 'string' ? value.split(',') : value)
  }

  const handleCustomerRiskStatusChange = (event: SelectChangeEvent<typeof customerRiskStatus>) => {
    const {
      target: { value }
    } = event
    setCustomerRiskStatus(typeof value === 'string' ? value.split(',') : value)
  }

  // Order risk statuses according to specified display order
  const orderedRiskStatuses = [
    buyerListRiskDecisions.Submitted,
    buyerListRiskDecisions.InReview,
    buyerListRiskDecisions.Rejected,
    buyerListRiskDecisions.Approved,
    buyerListRiskDecisions.OnHold
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={4} marginBottom={20}>
          <TextField
            label={orderFiltersContent.search.label}
            placeholder={orderFiltersContent.search.placeholder}
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleKeywordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            id="search-company"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{orderFiltersContent.orderStatus.label}</InputLabel>
            <Select
              labelId="multiple-status-label"
              id="multiple-status"
              multiple
              displayEmpty
              value={status}
              onChange={handleChange}
              input={<OutlinedInput label={orderFiltersContent.orderStatus.label} />}
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {data?.statuses?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={status.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{orderFiltersContent.paymentMethods.label}</InputLabel>
            <Select
              labelId="multiple-payment-method-label"
              id="multiple-payment-method"
              multiple
              displayEmpty
              value={paymentMethod}
              onChange={handlePaymentChange}
              input={<OutlinedInput label={orderFiltersContent.paymentMethods.label} />}
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {data?.paymentMethods?.map((d) => (
                <MenuItem key={d} value={d}>
                  <Checkbox checked={paymentMethod.indexOf(d) > -1} />
                  <ListItemText primary={d} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {moreFiltersEnabled && (
          <Grid item xs={3} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Customer risk status</InputLabel>
              <Select
                labelId="multiple-risk-status-label"
                id="multiple-risk-status"
                multiple
                displayEmpty
                value={customerRiskStatus}
                onChange={handleCustomerRiskStatusChange}
                input={<OutlinedInput label="Customer risk status" />}
                renderValue={(selected) => {
                  if (selected.length === 0) return ''
                  return selected.map((s) => orderedRiskStatuses.find((rs) => rs.value === s)?.display || s).join(', ')
                }}
              >
                {orderedRiskStatuses.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    <Checkbox checked={customerRiskStatus.includes(status.value)} />
                    <ListItemText primary={status.display} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {!hideSubmittedDateFilters && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={3} md={2}>
              <DatePicker
                label={orderFiltersContent.dateFrom.label}
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                format="DD-MM-YYYY"
                data-testid="from-date"
                disableFuture
                maxDate={dateTo}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small'
                  }
                }}
              />
            </Grid>

            <Grid item xs={3} md={2}>
              <DatePicker
                label={orderFiltersContent.dateTo.label}
                value={dateTo}
                data-cy="to-date"
                onChange={(newValue) => setDateTo(newValue)}
                format="DD-MM-YYYY"
                minDate={dateFrom}
                disableFuture
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small'
                  }
                }}
              />
            </Grid>
          </LocalizationProvider>
        )}
      </Grid>
    </>
  )
}

export default OrderListFilters
