import React from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import Paper from '@mui/material/Paper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Dayjs } from 'dayjs'

interface AdditionalOrderListFiltersProps {
  isVisible: boolean
  submittedDateFrom?: Dayjs | null
  submittedDateTo?: Dayjs | null
  advancedDateFrom?: Dayjs | null
  advancedDateTo?: Dayjs | null
  onSubmittedDateFromChange?: (date: Dayjs | null) => void
  onSubmittedDateToChange?: (date: Dayjs | null) => void
  onAdvancedDateFromChange?: (date: Dayjs | null) => void
  onAdvancedDateToChange?: (date: Dayjs | null) => void
}

const AdditionalOrderListFilters = ({
  isVisible,
  submittedDateFrom,
  submittedDateTo,
  advancedDateFrom,
  advancedDateTo,
  onSubmittedDateFromChange,
  onSubmittedDateToChange,
  onAdvancedDateFromChange,
  onAdvancedDateToChange
}: AdditionalOrderListFiltersProps) => {
  if (!isVisible) return null

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        data-cy="additional-filters"
        sx={{
          width: {
            xs: '100%',
            sm: '75%',
            md: '50%',
            lg: '25%'
          }
        }}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: theme.spacing(4),
            padding: theme.spacing(16),
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #E0E0E0',
            boxShadow: 'none'
          }}
        >
          <Stack spacing={4}>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginBottom: theme.spacing(4),
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  color: '#212529'
                }}
              >
                Submitted date
              </Typography>
              <Stack spacing={8}>
                <DatePicker
                  label="From"
                  value={submittedDateFrom}
                  onChange={onSubmittedDateFromChange}
                  format="DD-MM-YYYY"
                  disableFuture
                  maxDate={submittedDateTo || undefined}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      inputProps: {
                        'data-cy': 'submitted-date-from'
                      }
                    }
                  }}
                />
                <DatePicker
                  label="To"
                  value={submittedDateTo}
                  onChange={onSubmittedDateToChange}
                  format="DD-MM-YYYY"
                  disableFuture
                  minDate={submittedDateFrom || undefined}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      inputProps: {
                        'data-cy': 'submitted-date-to'
                      }
                    }
                  }}
                />
              </Stack>
            </Box>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginBottom: theme.spacing(4),
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  color: '#212529'
                }}
              >
                Advanced date
              </Typography>
              <Stack spacing={8}>
                <DatePicker
                  label="From"
                  value={advancedDateFrom}
                  onChange={onAdvancedDateFromChange}
                  format="DD-MM-YYYY"
                  disableFuture
                  maxDate={advancedDateTo || undefined}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      inputProps: {
                        'data-cy': 'advanced-date-from'
                      }
                    }
                  }}
                />
                <DatePicker
                  label="To"
                  value={advancedDateTo}
                  onChange={onAdvancedDateToChange}
                  format="DD-MM-YYYY"
                  disableFuture
                  minDate={advancedDateFrom || undefined}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      inputProps: {
                        'data-cy': 'advanced-date-to'
                      }
                    }
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </LocalizationProvider>
  )
}

export default AdditionalOrderListFilters
