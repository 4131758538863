import React, { useEffect, useState } from 'react'
import { Table, VisibilityState } from '@tanstack/react-table'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import ViewColumnOutlined from '@mui/icons-material/ViewColumnOutlined'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface ColumnVisibilityDropdownProps<T> {
  table: Table<T>
}

const ColumnVisibilityDropdown = <T extends object>({ table }: ColumnVisibilityDropdownProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // Only get columns that can be hidden/shown and aren't marked as permanently hidden
  const columns = table.getAllLeafColumns().filter((col) => {
    const columnDef = col.columnDef as { visible?: boolean; enableHiding?: boolean; header?: string }
    return columnDef.enableHiding !== false && columnDef.header !== undefined
  })
  const [visibility, setVisibility] = useState<VisibilityState>(table.getState().columnVisibility)

  // Update local state when table visibility changes
  useEffect(() => {
    setVisibility(table.getState().columnVisibility)
  }, [table.getState().columnVisibility])

  // Initialize visibility state if not already set
  useEffect(() => {
    const initialVisibility: VisibilityState = {}
    columns.forEach((column) => {
      if (visibility[column.id] === undefined) {
        initialVisibility[column.id] = true
      }
    })
    if (Object.keys(initialVisibility).length > 0) {
      const newVisibility = { ...visibility, ...initialVisibility }
      setVisibility(newVisibility)
      table.setColumnVisibility(newVisibility)
    }
  }, [columns])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleVisibilityChange = (columnId: string) => {
    const newColumnVisibility = { ...visibility }
    newColumnVisibility[columnId] = !newColumnVisibility[columnId]
    setVisibility(newColumnVisibility)
    table.setColumnVisibility(newColumnVisibility)
  }

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <IconButton
        onClick={handleClick}
        data-cy="column-visibility-dropdown"
        sx={{
          textTransform: 'none',
          color: '#212529',
          '&:hover': { backgroundColor: 'transparent' }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ViewColumnOutlined />
          <Typography color="#212529">Show/hide columns</Typography>
        </Box>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {columns.map((column) => (
          <MenuItem dense key={column.id} onClick={() => handleVisibilityChange(column.id)} sx={{ minWidth: 200 }}>
            <Checkbox size="small" checked={visibility[column.id] !== false} />
            <ListItemText primary={column.columnDef.header as string} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default ColumnVisibilityDropdown
