import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import { UseMutationResult } from 'react-query'
import { Theme } from '@emotion/react'
import { SxProps } from '@mui/system'

type ExportProps = {
  content: {
    button: string
    dialog: {
      heading: string
      body: string
      action: string
    }
  }
  buttonSx?: SxProps<Theme>
  mutation: ({
    onSuccess,
    onError
  }: {
    onSuccess?: () => void
    onError?: () => void
  }) => UseMutationResult<any, unknown, void, unknown>
}

const Export = (props: ExportProps) => {
  const [open, setOpen] = React.useState(false)

  const handleDialogClose = () => {
    setOpen(false)
  }

  const mutation = props.mutation({
    onSuccess: () => {
      setOpen(true)
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    mutation.mutate()
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        variant="text"
        startIcon={<ExitToAppIcon />}
        onClick={handleClick}
        sx={{ textTransform: 'none', typography: 'inputText', color: theme.palette.common.info, ...props.buttonSx }}
        data-cy="export-button"
        disabled={mutation.isLoading}
      >
        {props.content.button}
      </Button>

      <Dialog
        onClose={handleDialogClose}
        open={open}
        role="dialog"
        aria-labelledby="export-dialog-title"
        aria-describedby="export-content"
        maxWidth={'xs'}
      >
        <DialogTitle id="export-dialog-title" marginTop={theme.spacing(8)}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4"> {props.content.dialog.heading} </Typography>
            <div>
              <IconButton onClick={handleDialogClose} color="inherit">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent id="export-content" sx={{ paddingBottom: theme.spacing(12) }}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.common.textGrey100
            }}
          >
            {props.content.dialog.body}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ margin: theme.spacing(0, 8, 12) }}>
          <Button variant="contained" onClick={handleDialogClose} data-cy="ok-button">
            <Typography variant="body1"> {props.content.dialog.action} </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Export
