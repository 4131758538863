import React, { useState } from 'react'
import StyledTable, { StyledTableProps } from '../../components/StyledTable'
import { Dayjs } from 'dayjs'

import Box from '@mui/material/Box'
import Export from '../../components/export/Export'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { OrderDetails } from '../../types/orderDetails'
import OrderListFilters from './OrderListFilters'
import AdditionalOrderListFilters from './AdditionalOrderListFilters'
import OrderTotals from './OrderTotals'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { formatCurrency } from '../../utils/formatCurrency'
import { formatDate } from '../../utils/formatDate'
import { useOrderInfo } from '../../custom-hooks/useFetchOrdersQuery'
import { Link } from 'react-router-dom'
import { useExportOrders } from '../../custom-hooks/useExportOrders'
import content from '../content'
import { useFlag } from '@unleash/proxy-client-react'
import ColumnVisibilityDropdown from '../../components/ColumnVisibilityDropdown'
import useDebounce from '../../custom-hooks/useDebounce'
import { Table } from '@tanstack/react-table'
import ResetToDefault from '../../components/ResetToDefault'
import { defaultPageIndex, defaultPageSize } from '../../consts'
import IconButton from '@mui/material/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import theme from '../../assets/theme'

const OrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [status, setStatus] = useState<string[]>([])
  const [paymentMethod, setPaymentMethod] = useState<string[]>([])
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(null)
  const [dateTo, setDateTo] = useState<Dayjs | null>(null)
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
  const [submittedDateFrom, setSubmittedDateFrom] = useState<Dayjs | null>(null)
  const [submittedDateTo, setSubmittedDateTo] = useState<Dayjs | null>(null)
  const [advancedDateFrom, setAdvancedDateFrom] = useState<Dayjs | null>(null)
  const [advancedDateTo, setAdvancedDateTo] = useState<Dayjs | null>(null)
  const [customerRiskStatus, setCustomerRiskStatus] = useState<string[]>([])
  const defaultSorting = 'sourceCreatedDate'
  const defaultSortingOrderIsDescending = true
  const moreFiltersAdditionalColsFeatureEnabled = useFlag('ENT-903_MerchantPortal_OrderPage_AdditionalFiltersAndCols')

  const tableRef = React.useRef<HTMLTableElement>(null)
  const [tableInstance, setTableInstance] = useState<Table<OrderDetails> | null>(null)

  const fetchParams = {
    keyword: debouncedSearchTerm,
    orderStatus: status,
    paymentMethod: paymentMethod,
    dateFrom: dateFrom?.format('YYYY-MM-DD'),
    dateTo: dateTo?.format('YYYY-MM-DD'),
    submittedDateFrom: submittedDateFrom?.format('YYYY-MM-DD'),
    submittedDateTo: submittedDateTo?.format('YYYY-MM-DD'),
    advancedDateFrom: advancedDateFrom?.format('YYYY-MM-DD'),
    advancedDateTo: advancedDateTo?.format('YYYY-MM-DD'),
    customerRiskStatus: customerRiskStatus,
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize,
    sortBy: defaultSortingOrderIsDescending ? `-${defaultSorting}` : defaultSorting
  }

  const { data: ordersData, isLoading: isOrdersLoading } = useOrderInfo(fetchParams)

  const options: StyledTableProps<OrderDetails> = {
    enableColumnResizing: true,
    columns: [
      {
        property: 'sourceCreatedDate',
        header: 'Created date',
        cell: (row) => formatDate(row.getValue<string>('sourceCreatedDate'))
      },
      {
        property: 'invoiceReferenceNumber',
        header: 'Invoice number',
        sortable: true
      },
      {
        property: 'marketplaceOrderId',
        header: 'Order number',
        headerTooltip: "Even if the field is empty, it does not affect the order's funding",
        sortable: false
      },
      {
        property: 'status',
        header: 'Status',
        sortable: true,
        cell: (row) => row.getValue<string>('statusDisplayName')
      },
      {
        property: 'isConfirmedStatus',
        header: 'Delivery confirmed',
        headerTooltip:
          "We will make every effort to expedite the advance payment process. For this purpose, our team needs to validate the order's legitimacy, which requires delivery confirmation.\n\nThe most reliable delivery confirmation comes from your customer's direct response to our email. Alternatively, you have the option to submit supporting documents to facilitate the order review.\n\nPlease use the link provided in the column to upload documents related to the respective order. Additional information about supporting documents can be found on the uploading page.",
        sortable: true,
        cell: (row) => {
          const { id, isConfirmedStatus, canUploadEvidence } = row.original
          return isConfirmedStatus ? (
            'Yes'
          ) : (
            <div>
              No
              {canUploadEvidence && (
                <>
                  &nbsp;- <Link to={`/orders/${id}/delivery-confirmation`}>Upload document</Link>
                </>
              )}
            </div>
          )
        }
      },
      {
        property: 'companyName',
        header: 'Customer name',
        sortable: true
      },
      {
        property: 'nationalIdentifiers',
        header: 'National ID',
        headerTooltip: "The company's registration number in the territory where it is incorporated",
        cell: (row) => (
          <Box
            sx={{
              display: 'flex',
              textAlign: 'center',
              gap: 3
            }}
          >
            {row.getValue<string[]>('nationalIdentifiers')[0]}
            {row.getValue<string[]>('nationalIdentifiers').length > 1 && (
              <Tooltip
                title={<>{row.getValue<string[]>('nationalIdentifiers').slice(1).join(', ')}</>}
                data-cy="national-identifiers"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </Box>
        ),
        sortable: false
      },
      {
        property: 'amount',
        header: 'Invoice amount',
        cell: (row) => formatCurrency(row.getValue<number>('amount'), row.getValue<string>('currencyCode')),
        sortable: true
      },
      {
        property: 'internalFeeAmount',
        header: 'Kriya fee',
        cell: (row) => formatCurrency(row.getValue<number>('internalFeeAmount'), row.getValue<string>('currencyCode')),
        headerTooltip: 'The one-off fee is determined by your choice of payment method',
        sortable: false
      },
      {
        property: 'advanceAmount',
        header: 'Advance payment',
        cell: (row) => formatCurrency(row.getValue<number>('advanceAmount'), row.getValue<string>('currencyCode')),
        sortable: false
      },
      {
        property: 'paymentMethodDisplayName',
        header: 'Payment method',
        headerTooltip: `The payment method determines both the fee and due date:
      \u2022 PAY14 = Pay in 14 days
      \u2022 PAY30 = Pay in 30 days
      \u2022 PAY45 = Pay in 45 days
      \u2022 PAY60 = Pay in 60 days
      \u2022 PAY90 = Pay in 90 days
      \u2022 PAYEOM = Pay at end of the month

      Please note that not all of the payment methods will apply to your account. To add more payment methods, please contact your account manager.`,
        sortable: false
      },
      {
        property: 'advanceDate',
        header: 'Advanced date',
        cell: (row) => formatDate(row.getValue<string>('advanceDate')),
        sortable: true
      },
      {
        property: 'dueDate',
        header: 'Kriya due date',
        cell: (row) => formatDate(row.getValue<string>('dueDate')),
        sortable: true
      },
      {
        property: 'currencyCode',
        enableHiding: false
      },
      {
        property: 'statusDisplayName',
        enableHiding: false
      }
    ],
    fetchFn: useOrderInfo,
    sortingEnabled: true,
    defaultSorting: {
      name: defaultSorting,
      desc: defaultSortingOrderIsDescending
    },
    fetchOptions: fetchParams,
    dataCyAttributes: {
      table: 'orders-list-table',
      body: 'orders-list-table-body',
      head: 'orders-list-table-head'
    },
    tableRef: tableRef,
    onTableInstanceChange: setTableInstance,
    initialState: {
      columnVisibility: {
        currencyCode: false,
        statusDisplayName: false
      }
    }
  }

  if (moreFiltersAdditionalColsFeatureEnabled) {
    options.columns = [
      ...options.columns.slice(0, 3),
      {
        property: 'paymentReference',
        header: 'Payment reference',
        sortable: false,
        cell: (row) => row.getValue<string>('paymentReference')
      },
      ...options.columns.slice(3),
      {
        property: 'outstandingAmount',
        header: 'Outstanding amount',
        sortable: false,
        cell: (row) => {
          const amount = row.getValue<number>('outstandingAmount')
          const currency = row.getValue<string>('currencyCode')
          return amount != null ? formatCurrency(amount, currency) : '-'
        }
      }
    ]
  }

  const handleReset = () => {
    // Reset all filters
    setSearchTerm('')
    setStatus([])
    setPaymentMethod([])
    setDateFrom(null)
    setDateTo(null)
    setSubmittedDateFrom(null)
    setSubmittedDateTo(null)
    setAdvancedDateFrom(null)
    setAdvancedDateTo(null)
    setCustomerRiskStatus([])
    setShowAdditionalFilters(false)

    // Reset column visibility to default
    if (tableInstance) {
      const defaultVisibility = {
        currencyCode: false,
        statusDisplayName: false
      }
      tableInstance.setColumnVisibility(defaultVisibility)
    }
  }

  return (
    <>
      <Box sx={{ paddingBottom: theme.spacing(10) }}>
        <Typography variant="h1" data-cy="heading">
          Orders
        </Typography>

        <Box sx={{ paddingTop: theme.spacing(15) }}>
          <OrderListFilters
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            status={status}
            setStatus={setStatus}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            hideSubmittedDateFilters={moreFiltersAdditionalColsFeatureEnabled}
            customerRiskStatus={customerRiskStatus}
            setCustomerRiskStatus={setCustomerRiskStatus}
            moreFiltersEnabled={moreFiltersAdditionalColsFeatureEnabled}
          />
          {tableInstance && (
            <Box sx={{ paddingTop: theme.spacing(4), display: 'flex', gap: 2 }}>
              {moreFiltersAdditionalColsFeatureEnabled && (
                <IconButton
                  data-cy="toggle-additional-filters"
                  onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
                  sx={{
                    textTransform: 'none',
                    color: '#212529',
                    '&:hover': { backgroundColor: 'transparent' }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FilterAltIcon sx={{ fontSize: '16px' }} />
                    <Typography color="#212529">More filters</Typography>
                  </Box>
                </IconButton>
              )}
              <ColumnVisibilityDropdown table={tableInstance} />
              <ResetToDefault onReset={handleReset} />
            </Box>
          )}
          {showAdditionalFilters && (
            <AdditionalOrderListFilters
              isVisible={showAdditionalFilters}
              submittedDateFrom={submittedDateFrom}
              submittedDateTo={submittedDateTo}
              advancedDateFrom={advancedDateFrom}
              advancedDateTo={advancedDateTo}
              onSubmittedDateFromChange={setSubmittedDateFrom}
              onSubmittedDateToChange={setSubmittedDateTo}
              onAdvancedDateFromChange={setAdvancedDateFrom}
              onAdvancedDateToChange={setAdvancedDateTo}
            />
          )}
          <Box sx={{ mt: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {moreFiltersAdditionalColsFeatureEnabled && ordersData && ordersData.items.length > 0 && (
                <OrderTotals
                  invoiceAmount={ordersData.invoiceAmount}
                  advanceAmount={ordersData.advanceAmount}
                  kriyaFee={ordersData.kriyaFee}
                  outstandingAmount={ordersData.outstandingAmount}
                  currencyCode={ordersData.currencyCode ?? undefined}
                  isLoading={isOrdersLoading}
                />
              )}
              <Box sx={{ marginLeft: 'auto' }}>
                <Export content={content.pages.orderList.export} mutation={useExportOrders} />
              </Box>
            </Box>
          </Box>
          <StyledTable {...options} />
        </Box>
      </Box>
    </>
  )
}

export default OrdersPage
