import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { formatCurrency } from '../../utils/formatCurrency'
import theme from '../../assets/theme'

interface OrderTotalsProps {
  invoiceAmount: number
  advanceAmount: number
  kriyaFee: number
  outstandingAmount: number
  currencyCode?: string
  isLoading?: boolean
}

const OrderTotals = ({
  invoiceAmount,
  advanceAmount,
  kriyaFee,
  outstandingAmount,
  currencyCode,
  isLoading
}: OrderTotalsProps) => {
  const totals = [
    { label: 'Invoice amount', value: invoiceAmount },
    { label: 'Advanced amount', value: advanceAmount },
    { label: 'Kriya fee', value: kriyaFee },
    { label: 'Outstanding amount', value: outstandingAmount }
  ]

  if (isLoading) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(8),
        padding: theme.spacing(0)
      }}
    >
      {totals.map((total) => (
        <Box key={total.label} sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Typography variant="body1">{total.label}:</Typography>
          <Typography variant="body1" fontWeight="medium" ml={1}>
            {currencyCode ? formatCurrency(total.value, currencyCode) : '(multi-currency)'}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default OrderTotals
