import React from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

interface ResetToDefaultProps {
  onReset: () => void
}

const ResetToDefault = ({ onReset }: ResetToDefaultProps) => {
  return (
    <IconButton
      onClick={onReset}
      data-cy="reset-to-default-button"
      sx={{
        textTransform: 'none',
        color: '#212529',
        '&:hover': { backgroundColor: 'transparent' }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <RestartAltIcon />
        <Typography color="#212529">Reset to default</Typography>
      </Box>
    </IconButton>
  )
}

export default ResetToDefault
